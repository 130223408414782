import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
    FormControl,
    Grid,
    Typography,
    Button,
    TableContainer,
    Paper,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Table
} from '@mui/material';
import WrappedDatePicker from '../../components/common/WrappedDatePicker';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { getInvoiceStatistics, exportStatisticsToExcel, exportStatisticsToPdf } from '../../redux/invoice/actions'
import {
    Chart as ChartJS,
    PieController,
    ArcElement,
    LineController,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';
import './Statistics.css'
import { Line, Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { RiFileExcel2Fill } from 'react-icons/ri'
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import NumberFormat from 'react-number-format';
import WrappedAutocomplete from '../../components/common/WrappedAutocomplete';
import { PictureAsPdf } from '@mui/icons-material';
ChartJS.register(
    CategoryScale,
    PieController,
    LineController,
    ArcElement,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    ChartDataLabels
);

class DashboardStatisticsInvoice extends Component {

    emptyData = {
        invoiceTypes: null,
        startDate: null,
        endDate: null
    }

    constructor(props) {
        super(props);
        this.state = {
            selectedInvoiceType: [],
            selectedStartDate: null,
            selectedEndDate: null,
            data: this.emptyData
        };
    }

    componentWillUnmount() {
        this.props.invoiceReducer.invoiceStats = null
    }

    handleChangeStartDate = (date) => {
        let { data } = this.state
        data.startDate = moment(date)
        this.props.invoiceReducer.invoiceStats = null
        this.setState({ data: data, selectedStartDate: date });
    }

    handleChangeEndDate = (date) => {
        let { data } = this.state
        data.endDate = moment(date).add(24, 'hours')
        this.props.invoiceReducer.invoiceStats = null
        this.setState({ data: data, selectedEndDate: date });
    }

    handleChangeInvoiceType = (e, types) => {
        let { data } = this.state
        let selectedInvoiceType = { ...this.state.selectedInvoiceType }
        if (types.length === 0) {
            data.invoiceTypes = null
            this.props.invoiceReducer.invoiceStats = null
            this.setState({ data: data, selectedInvoiceType: [] });
        } else {
            selectedInvoiceType = types;
            data.invoiceTypes = []
            selectedInvoiceType.forEach(t => {
                data.invoiceTypes.push(t)
            })
            this.props.invoiceReducer.invoiceStats = null
            this.setState({ selectedInvoiceType: selectedInvoiceType, data: data });
        }
    }

    getStatistics = () => {
        this.props.getInvoiceStatistics(this.state.data)
    }

    randomColor = () => {
        const red = Math.floor((1 + Math.random()) * 256 / 2);
        const green = Math.floor((1 + Math.random()) * 256 / 2);
        const blue = Math.floor((1 + Math.random()) * 256 / 2);
        return "rgba(" + red + ", " + green + ", " + blue + ",0.4)";
    }

    render() {
        const { selectedStartDate, selectedEndDate, selectedInvoiceType } = this.state
        const { invoiceTypes, invoiceStats } = this.props.invoiceReducer

        const optionsPieChart = {
            plugins: {
                tooltip: {
                    callbacks: {
                        label: function (context) {
                            let label = context.label || '';
                            if (label) {
                                label += ': ';
                            }
                            if (context.parsed !== null) {
                                label += new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(context.parsed);
                            }
                            return label;
                        }
                    }
                },
                legend: {
                    display: true,
                    position: 'right',
                    labels: {
                        boxHeight: 8,
                        boxWidth: 20,
                        font: {
                            size: 10
                        }
                    }
                },
                datalabels: {
                    color: "grey",
                    font: {
                        weight: 500,
                        family: 'Roboto'
                    },
                    formatter(value, categories) {
                        let sum = 0;
                        let dataArr = categories.chart.data.datasets[0].data;
                        dataArr.map(data => {
                            return sum += Number(data)
                        });
                        let percentage = (value * 100 / sum).toFixed(2) + "%";
                        return percentage;
                    },
                },
                elements: {
                    arc: {
                        borderWidth: 0
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
            }
        }

        const optionsLineChart = {
            responsive: true,
            maintainAspectRatio: true,
            plugins: {
                tooltip: {
                    callbacks: {
                        label: function (context) {
                            let label = context.label || '';
                            if (label) {
                                label += ': ';
                            }
                            if (context.parsed !== null) {
                                label += new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(context.raw);
                            }
                            return label;
                        }
                    }
                },
                legend: {
                    display: true,
                    position: 'bottom'
                }
            },
            interaction: {
                mode: 'index',
                intersect: false
            },
            scales: {
                x: {
                    display: true,
                    title: {
                        display: true,
                        text: this.props.t("date")
                    }
                },
                y: {
                    display: true,
                    title: {
                        display: true,
                        text: this.props.t("amount")
                    }
                }
            }
        }

        let pieChartData = []
        invoiceStats && invoiceStats.valueItemList.map(item => {
            return pieChartData.push(item)
        })
        let lineChart = []
        if (pieChartData.length === 1) {
            pieChartData.map(data => {
                return lineChart.push(data.valueItems)
            })
        }

        return (
            <Grid container direction="row" spacing={2}>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h6">{this.props.t("statistics_for_invoices")}</Typography>
                    <Grid container direction="row" spacing={2}>
                        <Grid item container direction="column" xs={12} sm={12} md={4} lg={3} xl={4}>
                            <FormControl>
                                <WrappedAutocomplete
                                    label={this.props.t("select_payment_method")}
                                    placeholder={this.props.t("select_payment_method")}
                                    name="selectedInvoiceType"
                                    value={selectedInvoiceType}
                                    getOptionLabel={(option) => option ? this.props.t(option) : ""}
                                    options={invoiceTypes}
                                    multiple={true}
                                    onChange={(event, value) => {
                                        this.handleChangeInvoiceType(event, value)
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item container direction="column" xs={5} sm={5} md={3} lg={3} xl={2}>
                            <Grid item container>
                                <FormControl>
                                    <WrappedDatePicker
                                        label="start_date"
                                        name="startDate"
                                        onChange={this.handleChangeStartDate}
                                        value={selectedStartDate ? Date.parse(selectedStartDate) : null}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item container direction="column" xs={5} sm={5} md={3} lg={3} xl={2}>
                            <Grid item container>
                                <FormControl>
                                    <WrappedDatePicker
                                        label="end_date"
                                        name="endDate"
                                        onChange={this.handleChangeEndDate}
                                        value={selectedEndDate ? Date.parse(selectedEndDate) : null}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item container direction="column" xs={2} sm={2} md={1} lg={1} xl={1}>
                            <Grid item container>
                                <FormControl>
                                    <Button
                                        style={{ marginTop: '8px', height: '40px', width: '70px' }}
                                        title={this.props.t("get_statistics")}
                                        color="primary"
                                        onClick={() => this.getStatistics()}
                                        disabled={!selectedEndDate}>
                                        <QueryStatsIcon />
                                    </Button>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {invoiceStats && <Grid item container direction="column" xs={12} sm={12} md={12} lg={5} xl={5} mt={2}>
                    <PerfectScrollbar style={{ maxHeight: '85vh' }}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow >
                                        <TableCell style={{ fontWeight: 'bold' }}>{this.props.t("payment_method")}</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }} align="center">{this.props.t("date")}</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }} align="right">{this.props.t("total")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {invoiceStats.valueItemList.map((data, index) => {
                                        return <TableRow key={index}>
                                            <TableCell style={{ padding: '0px', paddingLeft: '15px' }}>
                                                <p style={{ height: data.valueItems.length > 1 ? 20 * data.valueItems.length : 20 }}>
                                                    {this.props.t(data.invoiceType)}
                                                </p>
                                                <p className='border-top-total'>
                                                    &nbsp;
                                                </p>
                                            </TableCell>
                                            <TableCell align='center' style={{ padding: '0px' }}>
                                                {data.valueItems.map((item1, index1) => {
                                                    return <Fragment key={index1}>
                                                        <p style={{ height: '20px', }}>
                                                            {moment.unix(item1.date).format("MM.YYYY.")}
                                                        </p>
                                                        {index1 + 1 === data.valueItems.length && <p className='border-top-total' style={{ backgroundColor: '#E7E8E7', paddingRight: '15px', textAlign: 'left' }}>
                                                            {this.props.t("Total") + " " + this.props.t(data.invoiceType)}
                                                        </p>}
                                                    </Fragment>
                                                })}
                                            </TableCell>
                                            <TableCell align='right' style={{ padding: '0px' }}>
                                                {data.valueItems.map((item1, index1) => {
                                                    return <Fragment key={index1}>
                                                        <p style={{ height: '20px', paddingRight: '10px' }}>
                                                            <NumberFormat value={item1.amount} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} suffix=" EUR" />
                                                        </p>
                                                        {index1 + 1 === data.valueItems.length && <p className='border-top-total' style={{ backgroundColor: '#E7E8E7', paddingRight: '10px' }}>
                                                            <NumberFormat value={data.total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} suffix=" EUR" />
                                                        </p>}
                                                    </Fragment>
                                                })}
                                            </TableCell>
                                        </TableRow>
                                    })}
                                    <TableRow style={{ borderTop: '1px solid black', backgroundColor: 'lightgray' }}>
                                        <TableCell style={{ fontWeight: 'bold' }}>{this.props.t("Total")}</TableCell>
                                        <TableCell colSpan={1} />
                                        <TableCell className="total-cell" align="right"><NumberFormat value={invoiceStats.total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} suffix=" EUR" /></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Button color="primary" className="default-button" style={{ marginTop: 10 }} onClick={() => this.props.exportStatisticsToExcel(this.state.data)} >
                            <RiFileExcel2Fill style={{ fontSize: '18px' }} /> {this.props.t("excel")}
                        </Button>
                        <Button color="error" className="default-button" style={{ marginTop: 10, marginLeft: 10 }} onClick={() => this.props.exportStatisticsToPdf(this.state.data)} >
                            <PictureAsPdf style={{ fontSize: '18px' }} /> {this.props.t("pdf")}
                        </Button>
                    </PerfectScrollbar>
                </Grid>}
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={1} mt={2}></Grid>
                {invoiceStats && invoiceStats.valueItemList.length > 1 && <Grid item container direction="column" xs={12} sm={10} md={6} lg={6} xl={5} mt={2}>
                    <div className="chart-container">
                        <Pie
                            data={{
                                labels: pieChartData.map(d => { return this.props.t(d.invoiceType) }),
                                datasets: [{
                                    data: pieChartData.map(data => { return data.total }),
                                    backgroundColor: pieChartData.map(data => { return this.randomColor() }),
                                    hoverOffset: 4
                                }]
                            }}
                            options={optionsPieChart}
                        />
                    </div>
                </Grid>}
                {invoiceStats && invoiceStats.valueItemList.length === 1 && lineChart[0].length > 1 && <Grid item container direction="column" xs={12} sm={10} md={6} lg={6} xl={6} mt={2}>
                    <div className="chart-container">
                        <Line
                            data={{
                                labels: lineChart[0].map(l => { return moment.unix(l.date).format("MM.YYYY.") }),
                                datasets: [{
                                    label: this.props.t(pieChartData[0].invoiceType),
                                    data: lineChart[0].map(l => { return l.amount }),
                                    backgroundColor: '#C0DCD2',
                                    borderColor: '#C0DCD2',
                                    hoverOffset: 4
                                }
                                ]
                            }}
                            options={optionsLineChart}
                            plugins={[ChartDataLabels]}
                        />
                    </div>
                </Grid>}
            </Grid >
        );
    }
}

const mapStateToProps = (state) => ({
    invoiceReducer: state.invoiceReducer
})

export default connect(mapStateToProps, { getInvoiceStatistics, exportStatisticsToExcel, exportStatisticsToPdf })(withTranslation()(DashboardStatisticsInvoice))
