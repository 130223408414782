import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import {
    Grid,
    TableCell,
    TableRow,
    Typography,
    Button,
    Zoom,
    Tooltip
} from '@mui/material';
import { Add, Edit, FileUpload, Delete } from '@mui/icons-material'
import Base58Table from "../../components/common/Base58Table";
import { getServiceGroupsList } from '../../redux/serviceGroup/actions'
import { getServices, deleteService, importServices, setServicesPage, setServicesSize, setServicesParams, changeServiceStatus } from '../../redux/service/actions'
import CreateOrUpdateService from './CreateOrUpdateService';
import ConfirmationDialog from '../../components/common/ConfirmationDialog';
import NumberFormat from 'react-number-format';
import ImportServicesModal from './ImportServicesModal';
import { hasRole } from '../../utils/Security';
import store from '../../redux/store';
import types from '../../redux/service/types';

class Services extends React.Component {

    emptyService = {
        id: null,
        name: '',
        comment: ''
    }

    constructor(props) {
        super(props);
        this.state = {
            serviceToDelete: this.emptyService,
            serviceToEdit: null,
            openDeleteDialog: false,
            dialogOpen: false,
            importDialog: false,
            fileName: '',
            fileData: ''
        };
    }

    componentDidMount() {
        this.props.getServices()
        this.props.getServiceGroupsList()
    }

    componentWillUnmount() {
        this.props.setServicesSize(10, true)
        store.dispatch({
            type: types.RESET_SERVICE_DATA
        })
    }

    closeDialog = () => {
        this.setState({ dialogOpen: false, serviceToEdit: null });
    }

    closeDeleteDialog = () => {
        this.setState({ openDeleteDialog: false, serviceToDelete: null });
    }

    closeImportDialog = () => {
        this.setState({ importDialog: false, fileName: null, fileData: null });
    }

    handleChangeFile = (e) => {
        let file = e.target.files[0];
        if (!file) {
            return;
        }
        const data = new FormData()
        data.append('file', file)
        this.setState({ fileName: file.name, fileData: data })
    }

    importFile = () => {
        this.props.importServices(this.state.fileData).then((response) => {
            if (response.status !== 400) {
                this.setState({ importDialog: false })
                this.props.getServices()
            }
        });
    }

    render() {
        const { dialogOpen, serviceToDelete, importDialog } = this.state;
        const { isLoading, services, servicesParams, servicesTotalElements } = this.props.serviceReducer;
        const { serviceGroupsList } = this.props.serviceGroupReducer

        let serviceList = services && services.map((service, index) => {
            return <TableRow key={index}>
                <TableCell>{service.serviceGroup && service.serviceGroup.length > 50 ? <Tooltip disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={service.serviceGroup} style={{ cursor: 'pointer' }}>
                    <span>{service.serviceGroup && service.serviceGroup.length > 50 ? service.serviceGroup.substring(0, 50) + "..." : service.serviceGroup}</span>
                </Tooltip> :
                    service.serviceGroup}</TableCell>

                <TableCell>{service.code && service.code.length > 15 ? <Tooltip disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={service.code} style={{ cursor: 'pointer' }}>
                    <span>{service.code && service.code.length > 15 ? service.code.substring(0, 15) + "..." : service.code}</span>
                </Tooltip> :
                    service.code}</TableCell>
                <TableCell>{service.name && service.name.length > 60 ? <Tooltip disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={service.name} style={{ cursor: 'pointer' }}>
                    <span>{service.name && service.name.length > 60 ? service.name.substring(0, 60) + "..." : service.name}</span>
                </Tooltip> :
                    service.name}</TableCell>
                <TableCell>{service.comment && service.comment.length > 40 ? <Tooltip disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={service.comment} style={{ cursor: 'pointer' }}>
                    <span>{service.comment && service.comment.length > 40 ? service.comment.substring(0, 40) + "..." : service.comment}</span>
                </Tooltip> :
                    service.comment}</TableCell>
                <TableCell style={{ textAlign: 'right', fontWeight: 'bold' }}><NumberFormat displayType="text" decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} value={service.price} suffix=" EUR" /></TableCell>
                <TableCell style={{ textAlign: 'right', fontWeight: 'bold' }}><NumberFormat displayType="text" decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} value={service.price * 7.53450} suffix=" HRK" /></TableCell>
                {hasRole(this.props.user, ["SUPERADMIN", "ADMIN", "SYSTEM"]) && <TableCell>
                    <Button title={this.props.t("edit")} className="default-button" style={{ marginRight: 10 }} color="secondary" onClick={() => this.setState({ dialogOpen: true, serviceToEdit: service.id })}>
                        <Edit />
                        {this.props.t("edit")}
                    </Button>
                    <Button title={this.props.t("delete")} className="default-button" style={{ marginRight: 10 }} color="primary" onClick={() => this.setState({ openDeleteDialog: true, serviceToDelete: service })}>
                        <Delete />
                        {this.props.t("delete")}
                    </Button>
                </TableCell>}
            </TableRow>
        })

        let columns = [
            {
                name: "name_service_group",
                dbName: "service_group",
                width: '12%',
                type: "auto-complete",
                items: serviceGroupsList.map(sg => { return sg.name }),
                filter: true,
            },
            {
                name: "code",
                dbName: 'code',
                width: '7%',
                sort: true,
                filter: true
            },
            {
                name: "name_service",
                dbName: 'name',
                width: '24%',
                sort: true,
                filter: true
            },
            {
                name: "comment",
                width: '16%',
            },
            {
                name: "price",
                dbName: 'price',
                width: '11%',
                type: 'number',
                thousandSeparator: ',',
                decimalSeparator: '.',
                decimalScale: 2,
                sort: true,
                filter: true
            },
            {
                name: "priceHRK",
                width: '10%'
            }
        ]

        const actions = { name: "", width: '17%' };

        if (hasRole(this.props.user, ["SUPERADMIN", "ADMIN", "SYSTEM"])) {
            columns.push(actions);
        }

        return (<>
            <Grid container direction="row" spacing={2}>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h5" style={{ alignItems: 'center', display: 'flex' }} mt={1}>
                        <span>{this.props.t("services")}</span>
                        {hasRole(this.props.user, ["SUPERADMIN", "ADMIN", "SYSTEM"]) && <Button color="primary" className="default-button" style={{ marginLeft: 'auto' }} onClick={() => this.setState({ dialogOpen: true })} >
                            <Add />{this.props.t("add_new_service")}
                        </Button>}
                        {hasRole(this.props.user, ["SUPERADMIN", "ADMIN", "SYSTEM"]) && <Button color="primary" className="default-button" style={{ marginLeft: '5px' }} onClick={() => this.setState({ importDialog: true })} >
                            <FileUpload />{this.props.t("import_services")}
                        </Button>}
                    </Typography>
                </Grid>
            </Grid>

            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12} mt={2}>
                <Base58Table
                    isLoading={isLoading}
                    columns={columns}
                    data={serviceList}
                    count={servicesTotalElements}
                    rowsPerPage={servicesParams.size}
                    page={servicesParams.page}
                    onPageChange={(e, page) => this.props.setServicesPage(page)}
                    onRowsPerPageChange={(e) => this.props.setServicesSize(e.target.value)}
                    onFilterChange={(params) => this.props.setServicesParams(params)}
                />
            </Grid>
            <CreateOrUpdateService
                isOpen={dialogOpen}
                onClose={this.closeDialog}
                serviceToEdit={this.state.serviceToEdit}
                onSave={() => this.setState({ dialogOpen: false, serviceToEdit: null }, () => this.props.getServices())}
            />
            <ConfirmationDialog
                isOpen={this.state.openDeleteDialog}
                title={this.props.t("delete_service")}
                message={<span dangerouslySetInnerHTML={{ __html: this.props.t("delete_service_message", { name: serviceToDelete && serviceToDelete.name }) }} />}
                onClose={() => this.setState({ openDeleteDialog: false })}
                onConfirm={() => this.props.changeServiceStatus(serviceToDelete.id, "false").then(() => {
                    this.setState({ openDeleteDialog: false }, () => this.props.getServices(), this.props.setServicesPage(0));
                })}
                closeButtonTitle={this.props.t("close")}
                confirmButtonTitle={this.props.t("delete")}
            />
            <ImportServicesModal
                isOpen={importDialog}
                onClose={this.closeImportDialog}
                fileName={this.state.fileName}
                importFile={this.importFile}
                handleChangeFile={this.handleChangeFile}
            />
        </>
        );
    }
}

const mapStateToProps = (state) => ({
    serviceReducer: state.serviceReducer,
    user: state.authReducer.user,
    serviceGroupReducer: state.serviceGroupReducer
})

const mapActionsToProps = { getServices, getServiceGroupsList, deleteService, importServices, setServicesPage, setServicesSize, setServicesParams, changeServiceStatus }

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(Services))
