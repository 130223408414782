import types from './types';
import patientType from '../patient/types'
import api from '../../api/examinationApi';
import { snackActions } from '../snackbar/reducer';
import i18next from 'i18next';

export const getExaminations = (config = {}) => async (dispatch) => {
    dispatch({
        type: types.SET_EXAMINATIONS_LOADING
    });
    await api.getExaminations(config).then(response => {
        dispatch({
            type: types.SET_EXAMINATIONS,
            payload: response.data
        });
    }).catch(error => {
        dispatch({
            type: types.SET_EXAMINATIONS,
            payload: []
        });
        dispatch(snackActions.error(i18next.t("get_examinations_error")));
    });
    return Promise.resolve();
}

export const getTodayExaminations = (config = {}) => async (dispatch) => {
    await api.getTodayExaminations(config).then(response => {
        dispatch({
            type: types.SET_TODAY_EXAMINATIONS,
            payload: response.data
        });
    }).catch(error => {
        dispatch({
            type: types.SET_TODAY_EXAMINATIONS,
            payload: []
        });
        dispatch(snackActions.error(i18next.t("get_today_examinations_error")));
    });
    return Promise.resolve();
}

export const searchExaminations = (config = {}) => async (dispatch) => {
    await api.searchExaminations(config).then(response => {
        dispatch({
            type: types.SET_EXAMINATIONS,
            payload: response.data
        });
    }).catch(error => {
        dispatch({
            type: types.SET_EXAMINATIONS,
            payload: []
        });
        dispatch(snackActions.error(i18next.t("get_search_examinations_error")));
    });
    return Promise.resolve();
}

export const createExamination = (data) => async (dispatch) => {
    return await api.createExamination(data).then((response) => {
        dispatch(snackActions.success(i18next.t("examination_created_successfully")));
        return response
    }).catch((error) => {
        dispatch(snackActions.error(i18next.t("examination_create_error")));
        return error.response
    });
}

export const updateExamination = (id, data) => async (dispatch) => {
    return await api.updateExamination(id, data).then((response) => {
        dispatch(snackActions.success(i18next.t("examination_updated_successfully")));
        return response
    }).catch((error) => {
        dispatch(snackActions.error(i18next.t("examination_update_error")));
        return error.response
    });
}

export const getExaminationById = (id, config = {}) => async (dispatch) => {
    return await api.getExaminationById(id, config).then(response => {
        dispatch({
            type: patientType.SET_CURRENT_PATIENT,
            payload: response.data
        })
        return response;
    }).catch(() => {
        dispatch(snackActions.error(i18next.t("get_examination_by_id_error")));
    });
}

export const exportExaminationByIdToPdf = (data) => async (dispatch) => {
    return await api.exportExaminationByIdToPdf(data, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/pdf'
        },
        responseType: 'blob'
    }).then(response => {
        handleContentDisposition(response)
        dispatch(snackActions.success(i18next.t("export_successfully")));
        return response;
    }).catch(error => {
        dispatch(snackActions.error(i18next.t("error_occurred")));
    });
}

export const exportExaminationsToPdf = (patientId) => async (dispatch) => {
    return await api.exportExaminationsToPdf({
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/pdf'
        },
        responseType: 'blob',
        params: {
            patientId: patientId
        }
    }).then(response => {
        handleContentDisposition(response)
        dispatch(snackActions.success(i18next.t("export_successfully")));
        return response;
    }).catch(error => {
        dispatch(snackActions.error(i18next.t("error_occurred")));
    });
}

export const exportExaminationByIdToDocx = (data) => async (dispatch) => {
    return await api.exportExaminationByIdToDocx(data, {
        headers: {
            'Content-Type': 'application/octet-stream',
            'Accept': 'application/octet-stream',
            'Access-Control-Expose-Headers': 'Content-Disposition'
        },
        responseType: 'blob'
    }).then(response => {
        handleContentDisposition(response)
        dispatch(snackActions.success(i18next.t("export_successfully")));
        return response;
    }).catch(error => {
        dispatch(snackActions.error(i18next.t("error_occurred")));
    });
}

export const exportExaminationsToDocx = (patientId) => async (dispatch) => {
    return await api.exportExaminationsToDocx({
        headers: {
            'Content-Type': 'application/octet-stream',
            'Accept': 'application/octet-stream',
            'Access-Control-Expose-Headers': 'Content-Disposition'
        },
        responseType: 'blob',
        params: {
            patientId: patientId
        }
    }).then(response => {
        handleContentDisposition(response)
        dispatch(snackActions.success(i18next.t("export_successfully")));
        return response;
    }).catch(error => {
        dispatch(snackActions.error(i18next.t("error_occurred")));
    });
}

export const handleContentDisposition = (response) => {
    const blob = new Blob([response.data]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    const contentDisposition = response.headers["content-disposition"];
    let fileName = contentDisposition.split("filename=")[1];
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
}

export const getExaminationStatistics = (data) => async (dispatch) => {
    await api.getExaminationStatistics(data).then((response) => {
        dispatch({
            type: types.SET_EXAMINATION_STATISTICS,
            payload: response.data
        })
    }).catch(() => {
        dispatch({
            type: types.SET_EXAMINATION_STATISTICS,
            payload: null
        })
    });
    return Promise.resolve();
}

export const exportStatisticsToExcel = (data) => async (dispatch) => {
    await api.exportStatisticsToExcel(data, {
        headers: {
            'Accept': 'application/octet-stream',
            'Access-Control-Expose-Headers': 'Content-Disposition'
        },
        responseType: 'blob'
    }).then((response) => {
        handleContentDisposition(response)
        dispatch(snackActions.success(i18next.t("export_successfully")));
    }).catch(() => {
        dispatch(snackActions.error(i18next.t("error_occurred")));
    });
}

export const exportStatisticsToPdf = (data) => async (dispatch) => {
    return await api.exportStatisticsToPdf(data, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/pdf'
        },
        responseType: 'blob'
    }).then(response => {
        handleContentDisposition(response)
        dispatch(snackActions.success(i18next.t("export_successfully")));
        return response;
    }).catch(error => {
        dispatch(snackActions.error(i18next.t("error_occurred")));
    });
}

export const deleteExamination = (id, config = {}) => async (dispatch) => {
    await api.deleteExamination(id, config).then((response) => {
        dispatch(snackActions.success(i18next.t("examination_deleted_successfully")));
    }).catch((error) => {
        dispatch(snackActions.error(i18next.t("error_occurred")));
    });
    return Promise.resolve();
}

export const getExaminationCountPerDoctor = (config = {}) => async (dispatch) => {
    await api.getExaminationCountPerDoctor(config).then(response => {
        dispatch({
            type: types.SET_EXAMINATION_PER_DOCTOR,
            payload: response.data
        });
    }).catch(error => {
        dispatch({
            type: types.SET_EXAMINATION_PER_DOCTOR,
            payload: []
        });
        dispatch(snackActions.error(i18next.t("get_examination_per_doctor_error")));
    });
    return Promise.resolve();
}