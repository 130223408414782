import types from './types';

const initialState = {
    invoices: [],
    inovicesLoading: false,
    invoicesTotalElements: 0,
    invoicesParams: {
        page: 0,
        size: 10,
        sort: 'invoiceReference,asc'
    },
    invoiceTypes: [],
    invoiceStats: null,
    loadingCreate: false,
}

export default function invoice(state = initialState, action) {
    switch (action.type) {
        case types.SET_INOVICE_LOADING:
            return {
                ...state,
                inovicesLoading: true
            }
        case types.SET_INVOICES:
            return {
                ...state,
                invoices: action.payload.content,
                invoicesTotalElements: action.payload ? action.payload.totalElements : 0,
                inovicesLoading: false
            }
        case types.SET_INVOICES_PAGE:
            return {
                ...state, invoicesParams: { ...state.invoicesParams, page: action.payload }
            }
        case types.SET_INVOICES_SIZE:
            return {
                ...state, invoicesParams: { ...state.invoicesParams, size: action.payload, page: 0 }
            }
        case types.SET_INVOICES_PARAMS:
            return {
                ...state,
                invoicesParams: action.payload
            }
        case types.SET_INVOICE_TYPES:
            return {
                ...state,
                invoiceTypes: action.payload
            }
        case types.SET_INVOICE_STATISTICS:
            return {
                ...state,
                invoiceStats: action.payload
            }
        case types.RESET_INVOICE_DATA:
            return {
                ...initialState,
                invoicesParams: {
                    page: 0,
                    size: 10,
                    sort: 'invoiceReference,asc'
                }
            }
        case types.SET_LOADING_CREATE_INVOICE:
            return {
                ...state,
                loadingCreate: action.payload
            }
        default:
            return state;
    }
}