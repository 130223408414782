import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import {
    Grid,
    LinearProgress,
    Box
} from '@mui/material';
import { Calendar as Scheduler, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'moment/locale/hr';
import CustomToolbar from '../calendar/CustomToolbar';
import CustomEvent from '../schedule/CustomEvent';
import overlap from 'react-big-calendar/lib/utils/layout-algorithms/overlap'
import { getInfirmaryList } from '../../redux/infrimary/actions'
import { hasRole } from '../../utils/Security';
import { getDoctors } from '../../redux/user/actions'
import { getScheduleEvents } from '../../redux/schedule/actions'
import CreateOrUpdateScheduleEvent from './CreateOrUpdateScheduleEvent';

class Schedule extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: false,
            selectedSlot: null,
            calendarEventToEdit: null,
            selectedBtn: 'day',
            navigateBtn: 'TODAY',
            date: new Date()
        }
    }

    componentDidMount() {
        Promise.all([this.props.getInfirmaryList(), this.props.getDoctors(), this.getScheduleEvents()])
            .then(() => {
            })
            .catch(error => {
            })
    }

    getCalendarEvents = (infirmaryIds) => {
        let { calendar } = this.state;
        let params = {}
        params.infirmaryIds = calendar.infirmaryIds.length > 0 ? calendar.infirmaryIds.join(",") : infirmaryIds && infirmaryIds.length > 0 ? infirmaryIds.join(",") : infirmaryIds;
        this.props.getCalendarEvents({ params: params })
    }

    closeDialog = () => {
        this.setState({ dialogOpen: false, calendarEventToEdit: null, selectedSlot: null })
    }

    getScheduleEvents = (event) => {
        let { date } = this.state;
        this.setState({ selectedBtn: event })
        switch (event) {
            case 'month':
                return this.props.getScheduleEvents({ startTime: moment(date).startOf('month').add(1, 'hours'), endTime: moment(date).endOf('month').add(1, 'hours') })
            case 'work_week':
                return this.props.getScheduleEvents({ startTime: moment(date).startOf('isoweek').add(1, 'hours'), endTime: moment(date).endOf('isoweek').add(1, 'hours') })
            case 'day':
                return this.props.getScheduleEvents({ startTime: moment(date).startOf('day').add(1, 'hours'), endTime: moment(date).endOf('day').add(1, 'hours') })
            default:
                return this.props.getScheduleEvents({ startTime: moment(date).startOf('day').add(1, 'hours'), endTime: moment(date).endOf('day').add(1, 'hours') })
        }
    }

    getScheduleEventsOnNavigateTabs = (event, view) => {
        if (new Date().toDateString() === event.toDateString()) {
            this.setState({ navigateBtn: 'TODAY', date: event })
        } else {
            this.setState({ navigateBtn: '', date: event })
        }
        switch (view) {
            case 'month':
                return this.props.getScheduleEvents({ startTime: moment(event).startOf('month').add(1, 'hours'), endTime: moment(event).endOf('month').add(1, 'hours') })
            case 'work_week':
                return this.props.getScheduleEvents({ startTime: moment(event).startOf('isoweek').add(1, 'hours'), endTime: moment(event).endOf('isoweek').add(1, 'hours') })
            case 'day':
                return this.props.getScheduleEvents({ startTime: moment(event).startOf('day').add(1, 'hours'), endTime: moment(event).endOf('day').add(1, 'hours') })
            default:
                return this.props.getScheduleEvents({ startTime: moment(event).startOf('day').add(1, 'hours'), endTime: moment(event).endOf('day').add(1, 'hours') })
        }
    }

    render() {
        const { selectedSlot } = this.state
        const { isLoading, scheduleEvents } = this.props.scheduleReducer

        if (isLoading) {
            return (
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            )
        }

        const customDayPropGetter = (date) => {
            let dayOfWeek = date.getDay();
            if (dayOfWeek === 0 || dayOfWeek === 6)
                return {
                    className: "day_weekend",
                    style: {
                        border: 'solid 1px ' + (dayOfWeek === 0 || dayOfWeek === 6 ? '#fc4242' : 'none'),
                    },
                }
            else return {}
        }

        return (<>
            <Grid container direction="row">
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid item container>
                        <Scheduler
                            localizer={momentLocalizer(moment)}
                            events={scheduleEvents}
                            defaultDate={new Date()}
                            dayPropGetter={customDayPropGetter}
                            startAccessor={(event) => { return new Date(moment(event.startTime).utc()) }}
                            endAccessor={(event) => { return new Date(moment(event.endTime).utc()) }}
                            titleAccessor={(event) => { return <> <span> {event.user.firstName + " " + event.user.lastName + " (" + event.infirmary.name + ") "}</span></> }}
                            min={new Date(0, 0, 0, 8, 0, 0)}
                            max={new Date(0, 0, 0, 20, 0, 0)}
                            components={{ toolbar: props => (<CustomToolbar {...props} selectedBtn={this.state.selectedBtn} navigateBtn={this.state.navigateBtn} />), event: CustomEvent }}
                            dayLayoutAlgorithm={(params) => {
                                return overlap({ ...params, minimumStartDifference: 30 })
                            }}
                            timeslots={1}
                            step={30}
                            onSelectSlot={(slot) => this.state.selectedBtn === "month" ? null : this.setState({ dialogOpen: true, selectedSlot: slot })}
                            onSelectEvent={event => event.id && this.setState({ dialogOpen: true, calendarEventToEdit: event })}
                            selectable
                            defaultView='day'
                            views={['month', 'work_week', 'day']}
                            onView={(event, date) => this.getScheduleEvents(event, date)}
                            onNavigate={(event, view) => this.getScheduleEventsOnNavigateTabs(event, view)}
                            popup={true}
                            culture={localStorage.getItem('i18nextLng') === 'hr' ? 'hr' : 'en'}
                            messages={localStorage.getItem('i18nextLng') === 'hr' ? {
                                date: this.props.t("date"),
                                time: this.props.t("time"),
                                event: this.props.t("event"),
                                allDay: this.props.t("all_day"),
                                week: this.props.t("week"),
                                work_week: this.props.t("work_week"),
                                day: this.props.t("day"),
                                month: this.props.t("month"),
                                previous: this.props.t("back"),
                                next: this.props.t("cal_next"),
                                yesterday: this.props.t("yesterday"),
                                tomorrow: this.props.t("tomorrow"),
                                today: this.props.t("today"),
                                agenda: this.props.t("agenda"),
                                noEventsInRange: this.props.t("no_event")
                            } : {}}
                            style={{ height: this.state.selectedBtn === "month" && "90vh", width: "100%" }}
                            eventPropGetter={event => {
                                const backgroundColor = event.infirmary.calendarHexColor ? event.infirmary.calendarHexColor : "#99d996";
                                const color = event.infirmary.calendarHexColor ? "black" : "white";
                                return { style: { backgroundColor, color } };
                            }}
                        />
                    </Grid>
                </Grid>
                {hasRole(this.props.authReducer.user, ["SUPERADMIN", "ADMIN", "SYSTEM", "RECEPTIONIST"]) && <CreateOrUpdateScheduleEvent
                    isOpen={this.state.dialogOpen}
                    onClose={this.closeDialog}
                    selectedSlot={selectedSlot}
                    calendarEventToEdit={this.state.calendarEventToEdit}
                    onSave={() => this.setState({ dialogOpen: false, calendarEventToEdit: null, selectedSlot: null }, () => this.getScheduleEvents(this.state.selectedBtn))}
                />}
            </Grid>
        </>
        );
    }
}

const mapStateToProps = (state) => ({
    scheduleReducer: state.scheduleReducer,
    authReducer: state.authReducer
})

const mapActionsToProps = { getInfirmaryList, getDoctors, getScheduleEvents }

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(Schedule))
