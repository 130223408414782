import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import {
    Grid,
    TableCell,
    TableRow,
    Typography,
    Button,
    Collapse,
    Box,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableBody,
    IconButton,
    Tooltip,
    Zoom
} from '@mui/material';
import { Edit, ArrowDownward, ArrowUpward, PictureAsPdf } from '@mui/icons-material'
import Base58Table from "../../components/common/Base58Table";
import { getInvoices, exportInvoiceToPdf, updateInvoice, setInvoicesPage, setInvoicesSize, setInvoicesParams, getInvoiceTypes } from '../../redux/invoice/actions'
import NumberFormat from 'react-number-format';
import CreateOrUpdateInvoice from './CreateOrUpdateInvoice';
import moment from 'moment';
import { hasRole } from '../../utils/Security';
import store from '../../redux/store';
import types from '../../redux/invoice/types';

class Invoices extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            collapseIndex: -1,
            dialogOpen: false,
            invoiceToEdit: null,
            totalAmount: 0
        };
    }

    componentDidMount() {
        hasRole(this.props.user, ["SUPERADMIN", "ADMIN", "SYSTEM", "RECEPTIONIST"]) && this.props.getInvoices()
        this.props.getInvoiceTypes()
    }

    componentWillUnmount() {
        this.props.setInvoicesSize(10, true)
        store.dispatch({
            type: types.RESET_INVOICE_DATA
        })
    }

    getTotal = (services) => {
        let total = 0;
        services.forEach(e => {
            total += Number(e.price * e.quantity);
        });
        return total;
    }

    getTotalDiscount = (services) => {
        let total = 0;
        services.forEach(e => {
            total += Number(e.discountAmount);
        });
        return total;
    }

    exportInvoiceToPdf = (id) => {
        this.props.exportInvoiceToPdf(id)
    }

    closeDialog = () => {
        this.setState({ dialogOpen: false, invoiceToEdit: null });
    }

    setInvoicesPage = (page) => {
        this.setState({ collapseIndex: -1 }, () => this.props.setInvoicesPage(page))
    }

    setInvoicesSize = (value) => {
        this.setState({ collapseIndex: -1 }, () => this.props.setInvoicesSize(value))

    }

    setInvoicesParams = (params) => {
        this.setState({ collapseIndex: -1 }, () => this.props.setInvoicesParams(params))
    }

    render() {
        const { collapseIndex, dialogOpen } = this.state
        const { invoicesLoading, invoices, invoicesTotalElements, invoicesParams, invoiceTypes } = this.props.invoiceReducer;

        let invoiceList = invoices && invoices.map((invoice, index) => {
            return <React.Fragment key={index}>
                <TableRow>
                    <TableCell>
                        <IconButton aria-label="expand row" size="small" onClick={() => this.setState({ collapseIndex: collapseIndex !== index ? index : -1 })}>
                            {index === collapseIndex ? <ArrowUpward /> : <ArrowDownward />}
                        </IconButton>
                    </TableCell>
                    <TableCell>{invoice.invoiceReference}</TableCell>
                    <TableCell>{this.props.t(invoice.invoiceType)}</TableCell>
                    <TableCell>{invoice.examination && invoice.examination.patient.firstName.length > 15 ? <Tooltip
                        disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={invoice.examination.patient.firstName} style={{ cursor: 'pointer' }}>
                        <span>{invoice.examination && invoice.examination.patient.firstName.length > 15 ? invoice.examination.patient.firstName.substring(0, 15) + "..." : invoice.examination.patient.firstName}</span>
                    </Tooltip> :
                        invoice.examination.patient.firstName}{" "}
                        {invoice.examination && invoice.examination.patient.lastName.length > 15 ? <Tooltip
                            disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={invoice.examination.patient.lastName} style={{ cursor: 'pointer' }}>
                            <span>{invoice.examination && invoice.examination.patient.lastName.length > 15 ? invoice.examination.patient.lastName.substring(0, 15) + "..." : invoice.examination.patient.lastName}</span>
                        </Tooltip> :
                            invoice.examination.patient.lastName}
                    </TableCell>
                    <TableCell>{moment(invoice.createdDate).format("DD.MM.YYYY. HH:mm")}</TableCell>
                    <TableCell>{invoice.createdBy}</TableCell>
                    {hasRole(this.props.user, ["SUPERADMIN", "ADMIN", "SYSTEM", "RECEPTIONIST"]) && <TableCell>
                        <Button title={this.props.t("edit")} className="default-button" style={{ marginRight: 10 }} color="primary" onClick={() => this.setState({ dialogOpen: true, invoiceToEdit: invoice.id })}>
                            <Edit />
                            {this.props.t("edit")}
                        </Button>
                        <Button title={this.props.t("pdf")} className="default-button" style={{ marginRight: 10 }} color="primary" aria-label="pdf" onClick={() => this.exportInvoiceToPdf(invoice.id)}>
                            <PictureAsPdf />
                            {this.props.t("export")}
                        </Button>
                    </TableCell>}
                </TableRow>
                <TableRow key={index}>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: '#ebebeb' }} colSpan={1} />
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: '#ebebeb' }} colSpan={5}>
                        <Collapse in={index === collapseIndex} timeout="auto" unmountOnExit style={{ paddingTop: 10, paddingBottom: 20 }}>
                            <Box margin={1}>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }} component="th" scope="row" width="5%">{this.props.t("code")}</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }} component="th" scope="row" width="45%">{this.props.t("name_service")}</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }} component="th" scope="row" width="5%">{this.props.t("quantity")}</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }} component="th" scope="row" align="right" width="12%">{this.props.t("price")}</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }} component="th" scope="row" align="right" width="15%">{this.props.t("discount_amount")}</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }} component="th" scope="row" align="right" width="18%">{this.props.t("amount")}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {invoice.examination.examinationServices.map((item, index) => {
                                                return <TableRow key={index}>
                                                    <TableCell >{item.service && item.service.code}</TableCell>
                                                    <TableCell >{item.service && item.service.name}</TableCell>
                                                    <TableCell align="center">{item.quantity}</TableCell>
                                                    <TableCell align="right"><NumberFormat value={item.price} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} suffix=" EUR" /></TableCell>
                                                    <TableCell align="right"><NumberFormat value={item.discountAmount} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} suffix=" EUR" /></TableCell>
                                                    <TableCell align="right"><NumberFormat value={item.quantity * item.price} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} suffix=" EUR" /></TableCell>
                                                </TableRow>
                                            })}
                                            <TableRow style={{ borderTop: '2px solid black' }}>
                                                <TableCell colSpan={4} />
                                                <TableCell style={{ fontWeight: 'bold' }}>{this.props.t("Total")}</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }} align="right"><NumberFormat value={this.getTotal(invoice.examination.examinationServices)} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} suffix=" EUR" /></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={4} />
                                                <TableCell style={{ fontWeight: 'bold' }}>{this.props.t("discount")}</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }} align="right"><NumberFormat value={this.getTotalDiscount(invoice.examination.examinationServices)} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} suffix=" EUR" /></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={4} />
                                                <TableCell style={{ fontWeight: 'bold' }}>{this.props.t("to_pay")}</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }} align="right">
                                                    <span>
                                                        <NumberFormat value={this.getTotal(invoice.examination.examinationServices) - this.getTotalDiscount(invoice.examination.examinationServices)} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} suffix=" EUR" />
                                                        {" / "}
                                                        <NumberFormat value={(this.getTotal(invoice.examination.examinationServices) - this.getTotalDiscount(invoice.examination.examinationServices)) * 7.53450} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} suffix=" HRK" />
                                                    </span>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={4} />
                                                <TableCell style={{ fontWeight: 'bold' }}>{this.props.t("fixed_conversion_rate")}</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }} align="right"><span>{"1 EUR = 7,53450 HRK"}</span></TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Collapse>
                    </TableCell>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: '#ebebeb' }} colSpan={1} />
                </TableRow>
            </React.Fragment>
        })

        let columns = [
            {
                name: "",
                width: '5%'
            },
            {
                name: "offer_reference",
                dbName: 'invoiceReference',
                width: '20%',
                sort: true,
                filter: true
            },
            {
                name: "payment_method",
                dbName: 'invoiceType',
                width: '15%',
                type: "auto-complete",
                items: invoiceTypes.map(it => { return it }),
                multiple: false,
                filter: true,
            },
            {
                name: "patient",
                dbName: "patient",
                width: '20%',
                filter: true
            },
            {
                name: "created_date",
                dbName: "createdDate",
                width: '10%',
                sort: true,
            },
            {
                name: "created_by",
                width: '15%'
            },
            {
                name: "",
                width: '15%'
            }
        ]

        return (<>
            <Grid container direction="row" spacing={2} >
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h5" style={{ alignItems: 'center', display: 'flex' }} mt={1}>
                        <span>{this.props.t("offers")}</span>
                    </Typography>
                </Grid>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12} mt={2}>
                    <Base58Table
                        isLoading={invoicesLoading}
                        columns={columns}
                        data={invoiceList}
                        count={invoicesTotalElements}
                        rowsPerPage={invoicesParams.size}
                        page={invoicesParams.page}
                        onPageChange={(e, page) => this.setInvoicesPage(page)}
                        onRowsPerPageChange={(e) => this.setInvoicesSize(e.target.value)}
                        onFilterChange={(params) => this.setInvoicesParams(params)}
                    />
                </Grid>
                <CreateOrUpdateInvoice
                    isOpen={dialogOpen}
                    onClose={this.closeDialog}
                    invoiceToEdit={this.state.invoiceToEdit}
                    onSave={() => this.setState({ dialogOpen: false, invoiceToEdit: null, collapseIndex: -1 }, () => this.props.getInvoices(), this.props.setInvoicesPage(0))}
                />
            </Grid>
        </>
        );
    }
}

const mapStateToProps = (state) => ({
    invoiceReducer: state.invoiceReducer,
    user: state.authReducer.user
})

const mapActionsToProps = { getInvoices, exportInvoiceToPdf, updateInvoice, setInvoicesPage, setInvoicesSize, setInvoicesParams, getInvoiceTypes }

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(Invoices))
