import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { AppBar, Button, IconButton, Toolbar, Menu, MenuItem, Divider, Avatar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import '../../components/sidebar/Sidebar.css';
import { connect } from 'react-redux';
import './Navbar.css'
import { withTranslation } from 'react-i18next';
import { FlagIcon } from "react-flag-kit";
import LogoutIcon from '@mui/icons-material/Logout';
import { updateInfirmaryForLogout, onLogout } from '../../redux/auth/actions';
import LogoutDialog from '../common/LogoutDialog'

class Navbar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isMenuOpen: false,
            openLogoutDialog: false
        }
    }

    handleMenu = (event) => {
        this.setState({ isMenuOpen: true, anchorEl: event.currentTarget })
    };


    handleClose = () => {
        this.setState({ isMenuOpen: false })
    };


    render() {
        return (
            <AppBar color="primary" position="fixed" className={this.props.location.pathname === '/choose-infirmary' ? 'appBar-full' : 'appBar'} >
                <Toolbar>
                    <IconButton
                        color="secondary"
                        aria-label="open drawer"
                        edge="start"
                        onClick={this.props.handleDrawerToggle}
                        className="menuButton">
                        <MenuIcon />
                    </IconButton>
                    <div className="app-Name">
                        {window.location.pathname.startsWith("/choose-infirmary") && "Medica"}
                    </div>
                    <div>
                        <span style={{ flexGrow: 2, fontWeight: 'bold', color: '#283630', marginRight: '15px' }}>{this.props.authReducer.user.currentInfirmary && this.props.authReducer.user.currentInfirmary.name}</span>
                        <IconButton
                            id="account-btn"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={this.handleMenu}>
                            <Avatar referrerPolicy="no-referrer" src={this.props.authReducer.user ? this.props.authReducer.user.imageUrl : ""} />
                        </IconButton>
                        <Menu
                            style={{ marginTop: 45, marginLeft: 80 }}
                            id="menu-appbar"
                            anchorEl={this.state.anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={this.state.isMenuOpen}

                            onClose={this.handleClose}>
                            <MenuItem ><span><b>{this.props.t("first_name")}:</b> {this.props.authReducer.user.firstName}</span></MenuItem>
                            <MenuItem ><span><b>{this.props.t("last_name")}:</b> {this.props.authReducer.user.lastName}</span></MenuItem>
                            <MenuItem ><span><b>{this.props.t("email")}:</b> {this.props.authReducer.user.email}</span></MenuItem>
                            <MenuItem ><span><b>{this.props.t("role")}:</b> {this.props.t(this.props.authReducer.user.role)}</span></MenuItem>
                            <Divider style={{ backgroundColor: '#87A397', height: 2, width: '100%' }} />
                            <MenuItem>
                                <Button className="default-button" onClick={() => this.setState({ openLogoutDialog: true })}>
                                    <LogoutIcon style={{ marginRight: '5px' }} /> {this.props.t("logout")}
                                </Button>
                            </MenuItem>
                        </Menu>
                        <IconButton onClick={() => this.props.i18n.changeLanguage("en")}>
                            <FlagIcon code={"GB"} size={24} />
                        </IconButton>
                        <IconButton onClick={() => this.props.i18n.changeLanguage("hr")}>
                            <FlagIcon code={"HR"} size={24} />
                        </IconButton>
                    </div>
                </Toolbar>
                <LogoutDialog
                    isOpen={this.state.openLogoutDialog}
                    title={this.props.t("logout")}
                    message={this.props.t("logout_message")}
                    onClose={() => this.setState({ openLogoutDialog: false })}
                    onConfirm={() => this.props.authReducer.user.currentInfirmary !== null ? this.props.updateInfirmaryForLogout(this.props.authReducer.user.currentInfirmary.id) : this.props.onLogout()}
                    closeButtonTitle={this.props.t("no")}
                    confirmButtonTitle={this.props.t("yes")}
                />
            </AppBar>
        );
    }
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer
})

export default connect(mapStateToProps, { updateInfirmaryForLogout, onLogout })(withRouter(withTranslation()(Navbar)));
