import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import {
    Chip,
    Grid,
    TableCell,
    TableRow,
    Typography,
    Button,
    Tooltip,
    Zoom
} from '@mui/material';
import { Add, Edit, Delete } from '@mui/icons-material';
import Base58Table from "../../components/common/Base58Table";
import { getInfirmaries, deleteInfirmary, setInfirmariesPage, setInfirmariesSize, setInfirmariesParams, getInfirmaryList } from '../../redux/infrimary/actions'
import { getDoctors } from '../../redux/user/actions'
import CreateOrUpdateInfirmary from './CreateOrUpdateInfirmary';
import ConfirmationDialog from '../../components/common/ConfirmationDialog';
import { hasRole } from '../../utils/Security';
import types from '../../redux/infrimary/types';
import store from '../../redux/store';

class Infirmaries extends React.Component {

    emptyInfirmary = {
        id: null,
        name: ''
    }

    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: false,
            infirmaryToEdit: null,
            infirmaryToDelete: this.emptyInfirmary,
            openDeleteDialog: false
        };
    }

    componentDidMount() {
        hasRole(this.props.user, ["SUPERADMIN", "ADMIN", "SYSTEM", "RECEPTIONIST"]) && this.props.getInfirmaries() && this.props.getInfirmaryList()
        hasRole(this.props.user, ["SUPERADMIN", "ADMIN", "SYSTEM"]) && this.props.getDoctors()
    }

    componentWillUnmount() {
        this.props.setInfirmariesSize(10, true)
        store.dispatch({
            type: types.RESET_INFIRMARY_DATA
        })
    }

    closeDialog = () => {
        this.setState({ dialogOpen: false, infirmaryToEdit: null });
    }

    deleteInfirmary = () => {
        this.props.deleteInfirmary(this.state.infirmaryToDelete.id).then((response) => {
            if (response.status !== 400) {
                this.setState({ openDeleteDialog: false }, () => this.props.getInfirmaries(), this.props.setInfirmariesPage(0));
            }
        })
    }

    render() {
        const { dialogOpen } = this.state;
        const { infirmariesLoading, infirmaries, infirmariesParams, infirmariesTotalElements } = this.props.infirmaryReducer;

        let infirmariesList = infirmaries && infirmaries.map((infirmary, index) => {
            return <TableRow key={index}>
                <TableCell>{infirmary.name && infirmary.name.length > 55 ? <Tooltip disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={infirmary.name} style={{ cursor: 'pointer' }}>
                    <span>{infirmary.name && infirmary.name.length > 55 ? infirmary.name.substring(0, 55) + "..." : infirmary.name}</span>
                </Tooltip> :
                    infirmary.name}</TableCell>
                <TableCell>{infirmary.currentOccupant && infirmary.currentOccupant.firstName + " " + infirmary.currentOccupant.lastName}</TableCell>
                <TableCell>{infirmary.comment && infirmary.comment.length > 35 ? <Tooltip disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={infirmary.comment} style={{ cursor: 'pointer' }}>
                    <span>{infirmary.comment && infirmary.comment.length > 35 ? infirmary.comment.substring(0, 35) + "..." : infirmary.comment}</span>
                </Tooltip> :
                    infirmary.comment}</TableCell>
                <TableCell><Chip style={{ backgroundColor: infirmary.calendarHexColor, width: '32px' }}></Chip></TableCell>
                <TableCell>
                    <Button title={this.props.t("edit")} className="default-button" style={{ marginRight: 10 }} color="secondary" onClick={() => this.setState({ infirmaryToEdit: infirmary.id, dialogOpen: true })}>
                        <Edit />
                        {this.props.t("edit")}
                    </Button>
                    <Button title={this.props.t("delete")} className="default-button" color="primary" onClick={() => this.setState({ infirmaryToDelete: infirmary, openDeleteDialog: true })}>
                        <Delete />
                        {this.props.t("delete")}
                    </Button>
                </TableCell>
            </TableRow>
        })

        let columns = [
            {
                name: "name_infirmary",
                dbName: "name",
                width: '35%',
                sort: true,
                filter: true
            },
            {
                name: "current_occupant",
                dbName: "current_occupant",
                width: '20%',
                sort: true
            },
            {
                name: "comment",
                dbName: "comment",
                width: '18%',
                sort: true
            },
            {
                name: "background_color",
                width: '9%'
            },
            {
                name: '',
                width: '18%'
            }
        ]

        return (<>
            <Grid container direction="row" spacing={2}>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h5" style={{ alignItems: 'center', display: 'flex' }} mt={1}>
                        <span>{this.props.t("infirmaries")}</span>
                        <Button color="primary" className="default-button" style={{ marginLeft: 'auto' }} onClick={() => this.setState({ dialogOpen: true })} >
                            <Add />{this.props.t("new_infirmary")}
                        </Button>
                    </Typography>
                </Grid>
            </Grid>
            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12} mt={2}>
                <Base58Table
                    isLoading={infirmariesLoading}
                    columns={columns}
                    data={infirmariesList}
                    count={infirmariesTotalElements}
                    rowsPerPage={infirmariesParams.size}
                    page={infirmariesParams.page}
                    onPageChange={(e, page) => this.props.setInfirmariesPage(page)}
                    onRowsPerPageChange={(e) => this.props.setInfirmariesSize(e.target.value)}
                    onFilterChange={(params) => this.props.setInfirmariesParams(params)}
                />
            </Grid>
            <CreateOrUpdateInfirmary
                isOpen={dialogOpen}
                onClose={this.closeDialog}
                infirmaryToEdit={this.state.infirmaryToEdit}
                onSave={() => this.setState({ dialogOpen: false, infirmaryToEdit: null }, () => { this.props.getInfirmaries(); this.props.getInfirmaryList() })} />
            <ConfirmationDialog
                isOpen={this.state.openDeleteDialog}
                title={this.props.t("delete_infirmary")}
                message={<span dangerouslySetInnerHTML={{ __html: this.props.t("delete_infirmary_message", { name: this.state.infirmaryToDelete.name }) }} />}
                onClose={() => this.setState({ openDeleteDialog: false })}
                onConfirm={() => this.deleteInfirmary()}
                closeButtonTitle={this.props.t("close")}
                confirmButtonTitle={this.props.t("delete")}
            />
        </>
        );
    }
}

const mapStateToProps = (state) => ({
    infirmaryReducer: state.infirmaryReducer,
    user: state.authReducer.user
})

const mapActionsToProps = { getInfirmaries, getDoctors, deleteInfirmary, setInfirmariesPage, setInfirmariesSize, setInfirmariesParams, getInfirmaryList }

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(Infirmaries))
