import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import {
    Grid,
    TableCell,
    TableRow,
    Typography,
    Button
} from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Base58Table from "../../components/common/Base58Table";
import { getDiagnoses, importDiagnoses, setDiagnosesPage, setDiagnosesSize, setDiagnosesParams, deleteDiagnosis } from '../../redux/diagnosis/actions'
import ImportDiagnosesModal from './ImportDiagnosesModal';
import { hasRole } from '../../utils/Security';
import { Add, Delete, Edit } from '@mui/icons-material';
import CreateOrUpdateDiagnosis from './CreateOrUpdateDiagnosis';
import ConfirmationDialog from '../../components/common/ConfirmationDialog';
import store from '../../redux/store';
import types from '../../redux/diagnosis/types';

class Diagnoses extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: false,
            fileName: null,
            fileData: null,
            importDialog: false,
            diagnosisToEdit: null,
            openDeleteDialog: false,
            diagnosisToDelete: null
        };
    }

    componentDidMount() {
        this.props.getDiagnoses()
    }

    componentWillUnmount() {
        this.props.setDiagnosesSize(20, true)
        store.dispatch({
            type: types.RESET_DIAGNOSES_DATA
        })
    }

    closeImportDialog = () => {
        this.setState({ importDialog: false, fileName: null, fileData: null });
    }

    closeDialog = () => {
        this.setState({ dialogOpen: false, diagnosisToEdit: null });
    }

    handleChangeFile = (e) => {
        let file = e.target.files[0];
        if (!file) {
            return;
        }
        const data = new FormData()
        data.append('file', file)
        this.setState({ fileName: file.name, fileData: data })
    }

    importFile = () => {
        this.props.importDiagnoses(this.state.fileData).then((response) => {
            if (response.status !== 400) {
                this.setState({ importDialog: false })
                this.props.getDiagnoses()
            }
        });
    }

    render() {
        const { dialogOpen, importDialog, diagnosisToDelete } = this.state;
        const { diagnosesLoading, diagnoses, diagnosesParams, diagnosesTotalElements } = this.props.diagnosisReducer;

        let diagnosesList = diagnoses && diagnoses.map((diagnosis, index) => {
            return <TableRow key={index}>
                <TableCell>{diagnosis.code}</TableCell>
                <TableCell>{diagnosis.name}</TableCell>
                <TableCell>{diagnosis.latinName}</TableCell>
                {hasRole(this.props.user, ["SUPERADMIN", "ADMIN", "SYSTEM", "DOCTOR"]) && <TableCell>
                    <Button title={this.props.t("edit")} className="default-button" style={{ marginRight: 10 }} color="secondary" onClick={() => this.setState({ dialogOpen: true, diagnosisToEdit: diagnosis.id })}>
                        <Edit />
                        {this.props.t("edit")}
                    </Button>
                    <Button title={this.props.t("delete")} className="default-button" color="primary" onClick={() => this.setState({ diagnosisToDelete: diagnosis, openDeleteDialog: true })}>
                        <Delete />
                        {this.props.t("delete")}
                    </Button>
                </TableCell>}
            </TableRow>
        })

        let columns = [
            {
                name: "code",
                dbName: 'code',
                width: '15%',
                sort: true,
                filter: true
            },
            {
                name: "name_diagnosis",
                dbName: 'name',
                width: '35%',
                sort: true,
                filter: true
            },
            {
                name: "latin_name",
                dbName: 'latinName',
                width: '30%',
                filter: true
            },
            {
                name: "",
                width: '20%'
            }
        ]

        return (<>
            <Grid container direction="row" spacing={2}>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h5" style={{ alignItems: 'center', display: 'flex' }} mt={1}>
                        <span>{this.props.t("diagnoses")}</span>
                        {hasRole(this.props.user, ["SUPERADMIN", "ADMIN", "SYSTEM", "DOCTOR"]) && <Button color="primary" className="default-button" style={{ marginLeft: 'auto' }} onClick={() => this.setState({ dialogOpen: true })} >
                            <Add />{this.props.t("add_new_diagnosis")}
                        </Button>}
                        {hasRole(this.props.user, ["SUPERADMIN", "ADMIN", "SYSTEM"]) && <Button color="primary" className="default-button" style={{ marginLeft: '5px' }} onClick={() => this.setState({ importDialog: true })} >
                            <FileUploadIcon />{this.props.t("import_diagnoses")}
                        </Button>}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12} mt={2}>
                <Base58Table
                    isLoading={diagnosesLoading}
                    columns={columns}
                    data={diagnosesList}
                    rowsPerPageOptions={[10, 20, 30, 40]}
                    count={diagnosesTotalElements}
                    rowsPerPage={diagnosesParams.size}
                    page={diagnosesParams.page}
                    onPageChange={(e, page) => this.props.setDiagnosesPage(page)}
                    onRowsPerPageChange={(e) => this.props.setDiagnosesSize(e.target.value)}
                    onFilterChange={(params) => this.props.setDiagnosesParams(params)}
                />
            </Grid>
            <ImportDiagnosesModal
                isOpen={importDialog}
                onClose={this.closeImportDialog}
                fileName={this.state.fileName}
                importFile={this.importFile}
                handleChangeFile={this.handleChangeFile}
            />
            <CreateOrUpdateDiagnosis
                isOpen={dialogOpen}
                onClose={this.closeDialog}
                diagnosisToEdit={this.state.diagnosisToEdit}
                onSave={() => this.setState({ dialogOpen: false, diagnosisToEdit: null }, () => this.props.getDiagnoses())}
            />
            <ConfirmationDialog
                isOpen={this.state.openDeleteDialog}
                title={this.props.t("delete_diagnosis")}
                message={<span dangerouslySetInnerHTML={{ __html: this.props.t("delete_diagnosis_message", { name: diagnosisToDelete && diagnosisToDelete.name }) }} />}
                onClose={() => this.setState({ openDeleteDialog: false })}
                onConfirm={() => this.props.deleteDiagnosis(diagnosisToDelete.id).then(() => {
                    this.setState({ openDeleteDialog: false }, () => this.props.getDiagnoses(), this.props.setDiagnosesPage(0));
                })}
                closeButtonTitle={this.props.t("close")}
                confirmButtonTitle={this.props.t("delete")}
            />
        </>
        );
    }
}

const mapStateToProps = (state) => ({
    diagnosisReducer: state.diagnosisReducer,
    user: state.authReducer.user
})

const mapActionsToProps = { getDiagnoses, importDiagnoses, setDiagnosesPage, setDiagnosesSize, setDiagnosesParams, deleteDiagnosis }

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(Diagnoses))
