import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
    Grid,
    Button,
    ButtonGroup
} from '@mui/material';
import Toolbar from 'react-big-calendar/lib/Toolbar';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { hasRole } from '../../utils/Security';

class CustomToolbar extends Toolbar {

    constructor(props) {
        super(props);
        this.state = {
            selectedBtn: this.props.selectedBtn ? this.props.selectedBtn : 'day',
            navigateBtn: this.props.navigateBtn ? this.props.navigateBtn : "",
            view: this.props.view ? this.props.view : "day"
        };
    }

    componentDidUpdate() {
        if (this.props.view === 'day' && this.props.selectedBtn === 'day') {
            this.props.onView("day")
        } else {
            this.props.onView(this.state.selectedBtn)
        }
    }

    render() {
        return (
            <Grid container direction="row" style={{ marginBottom: '10px' }}>
                <Grid container item direction="column" xs={12} sm={5} md={5} lg={5} xl={5}>
                    {hasRole(this.props.user, ["SUPERADMIN", "ADMIN", "SYSTEM", "RECEPTIONIST", "DOCTOR"]) && <ButtonGroup size="small" variant="contained" aria-label="outlined primary button group" style={{ marginRight: "auto" }}>
                        <Button onClick={() => this.setState({ navigateBtn: "TODAY" }, () => this.props.onNavigate("TODAY"))} className={this.state.navigateBtn === "TODAY" ? "calendar-nav-btn-selected" : "unset"}>{this.props.t("today")}</Button>
                        <Button className="table-fab" onClick={() => this.setState({ navigateBtn: "" }, () => this.props.onNavigate('PREV'))}><ArrowBackIosIcon className="table-fab-icon" /></Button>
                        <Button className="table-fab" onClick={() => this.setState({ navigateBtn: "" }, () => this.props.onNavigate('NEXT'))}><ArrowForwardIosIcon className="table-fab-icon" /></Button>
                    </ButtonGroup>}
                </Grid>
                <Grid item container direction="column" xs={12} sm={2} md={2} lg={2} xl={2} style={{ textAlign: "center" }}>{this.props.label}</Grid>
                <Grid item container direction="column" xs={12} sm={5} md={5} lg={5} xl={5}>
                    <ButtonGroup size="small" variant="contained" aria-label="outlined primary button group" style={{ marginLeft: "auto" }}>
                        {hasRole(this.props.user, ["SUPERADMIN", "ADMIN", "SYSTEM", "RECEPTIONIST"]) && <Button onClick={() => this.setState({ selectedBtn: "month" }, () => this.props.onView("month"))} className={this.state.selectedBtn === "month" ? "calendar-nav-btn-selected" : "unset"}>{this.props.t("month")}</Button>}
                        {hasRole(this.props.user, ["SUPERADMIN", "ADMIN", "SYSTEM", "RECEPTIONIST"]) && <Button onClick={() => this.setState({ selectedBtn: "work_week" }, () => this.props.onView("work_week"))} className={this.state.selectedBtn === "work_week" ? "calendar-nav-btn-selected" : "unset"}>{this.props.t("week")}</Button>}
                        {hasRole(this.props.user, ["SUPERADMIN", "ADMIN", "SYSTEM", "RECEPTIONIST"]) && <Button onClick={() => this.setState({ selectedBtn: "day" }, () => this.props.onView("day"))} className={this.state.selectedBtn === "day" ? "calendar-nav-btn-selected" : "unset"}>{this.props.t("day")} </Button>}
                        {/* <Button onClick={() => this.setState({ selectedBtn: "agenda" }, () => this.props.onView("agenda"))} className={this.state.selectedBtn === "agenda" ? "calendar-nav-btn-selected" : "unset"}>{this.props.t("agenda")}</Button> */}
                    </ButtonGroup>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.authReducer.user
});

export default connect(mapStateToProps, {})(withTranslation()(CustomToolbar));
