import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import {
    Grid,
    TableCell,
    TableRow,
    Typography,
    Fab,
    Button
} from '@mui/material';
import { Delete, FindInPage } from '@mui/icons-material';
import Base58Table from "../../components/common/Base58Table";
import { getAuditLogs, setAuditLogsPage, setAuditLogsSize, setAuditLogsParams } from '../../redux/auditLog/actions'
import moment from 'moment';
import ParamsDetailsDialog from './ParamsDetailsDialog';
import DeleteAuditLogsDialog from './DeleteAuditLogsDialog';

class AuditLog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            selectedParams: null,
            dialogOpen: false
        };
    }

    componentDidMount() {
        this.props.getAuditLogs()
    }

    getParamDetails = (index) => {
        const { auditLogs } = this.props.auditReducer;
        let selectedParams = auditLogs[index].params;
        if (this.isJsonString(selectedParams.rawResponse)) {
            let rawResponse = JSON.parse(selectedParams.rawResponse);
            selectedParams.rawResponse = rawResponse;
        }
        this.setState({ selectedParams: selectedParams });
    }

    isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    getStatus = (status) => {
        switch (status) {
            case "SUCCESS": {
                return <span style={{ color: 'green' }}>{status}</span>
            }
            case "ERROR": {
                return <span style={{ color: 'red' }}>{status}</span>
            }
            default:
                return
        }
    }

    closeDialog = () => {
        this.setState({ dialogOpen: false });
    }

    render() {
        let { dialogOpen } = this.state
        const { auditLogsLoading, auditLogs, auditLogsTotalElements, auditLogsParams, auditLogsActions } = this.props.auditReducer;

        let auditLogList = auditLogs && auditLogs.map((auditLog, index) => {
            return <TableRow key={index}>
                <TableCell>{auditLog.action}</TableCell>
                <TableCell>{moment.unix(auditLog.timestamp).format("DD.MM.YYYY. HH:mm")}</TableCell>
                <TableCell>{auditLog.initiator}</TableCell>
                <TableCell>{this.getStatus(auditLog.status)}</TableCell>
                <TableCell>
                    <Fab color="secondary" onClick={() => this.setState({ isOpen: true }, () => this.getParamDetails(index))}>
                        <FindInPage />
                    </Fab>
                </TableCell>
            </TableRow >
        })

        let columns = [
            {
                name: "action",
                dbName: "action",
                type: "auto-complete",
                items: auditLogsActions,
                multiple: false,
                width: '20%',
                filter: true,
                sort: false,
            },
            {
                name: "time",
                width: '25%'
            },
            {
                name: "caller",
                dbName: "caller",
                width: '25%',
                filter: true
            },
            {
                name: "status",
                dbName: "status",
                width: '15%',
                filter: true
            },
            {
                name: '',
                width: '15%'
            }
        ]

        return (<>
            <Grid container direction="row" spacing={2}>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h5" style={{ alignItems: 'center', display: 'flex' }} mt={1}>
                        <span>{this.props.t("change_logs")}</span>
                        <Button color="primary" className="default-button" style={{ marginLeft: 'auto' }} onClick={() => this.setState({ dialogOpen: true })} >
                            <Delete />{this.props.t("delete_audit_logs")}
                        </Button>
                    </Typography>
                </Grid>
            </Grid>
            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12} mt={2}>
                <Base58Table
                    isLoading={auditLogsLoading}
                    columns={columns}
                    data={auditLogList}
                    count={auditLogsTotalElements}
                    rowsPerPage={auditLogsParams.size}
                    page={auditLogsParams.page}
                    onPageChange={(e, page) => this.props.setAuditLogsPage(page)}
                    onRowsPerPageChange={(e) => this.props.setAuditLogsSize(e.target.value)}
                    onFilterChange={(params) => this.props.setAuditLogsParams(params)}
                />
            </Grid>
            <ParamsDetailsDialog
                isOpen={this.state.isOpen}
                onClose={() => this.setState({ isOpen: false })}
                closeButtonTitle={this.props.t("close")}
                selectedParams={this.state.selectedParams}
            />
            <DeleteAuditLogsDialog
                isOpen={dialogOpen}
                onClose={this.closeDialog}
                onSave={() => this.setState({ dialogOpen: false }, () => this.props.getAuditLogs())} />
        </>
        );
    }
}

const mapStateToProps = (state) => ({
    auditReducer: state.auditReducer,
    user: state.authReducer.user
})

const mapActionsToProps = { getAuditLogs, setAuditLogsPage, setAuditLogsSize, setAuditLogsParams }

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(AuditLog))
