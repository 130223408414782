import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import {
    Chip,
    Grid,
    LinearProgress,
    Box,
    FormControl,
    Typography,
    Divider,
    CardContent,
    Card,
    Tooltip,
    Zoom,
    Button
} from '@mui/material';
import "./Calendar.css"
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Calendar as Scheduler, momentLocalizer } from 'react-big-calendar'
import PersonPinIcon from '@mui/icons-material/PersonPin';
import moment from 'moment'
import 'moment/locale/hr';
import CustomEvent from './CustomEvent';
import CustomToolbar from './CustomToolbar';
import CreateOrUpdateEvent from './CreateOrUpdateEvent';
import { getInfirmaryList } from '../../redux/infrimary/actions'
import { getScheduleEvents } from '../../redux/schedule/actions'
import { getPatientList } from '../../redux/patient/actions'
import { getCalendarEvents, deleteCalendarEntry } from '../../redux/calendar/actions'
import { hasRole } from '../../utils/Security';
import store from '../../redux/store';
import { snackActions } from '../../redux/snackbar/reducer';
import WrappedAutocomplete from '../../components/common/WrappedAutocomplete';
import { Search } from '@mui/icons-material';
import SearchPatientCalendarEntryModal from './SearchPatientCalendarEntryModal';

moment.locale('ko', {
    week: {
        dow: 1,
        doy: 1,
    },
});

class Calendar extends React.Component {

    emptyCalendarEntry = {
        infirmaryIds: []
    }

    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: false,
            selectedInfirmary: [],
            selectedSlot: null,
            calendarEventToEdit: null,
            calendar: this.emptyCalendarEntry,
            selectedBtn: 'day',
            navigateBtn: 'TODAY',
            isLoadingEvents: true,
            selectedDay: new Date(),
            view: '',
            searchPatientDialog: false
        };
    }

    componentDidMount() {
        Promise.all([this.props.getInfirmaryList(), hasRole(this.props.authReducer.user, ["SUPERADMIN", "ADMIN", "SYSTEM", "RECEPTIONIST"]) && this.props.getPatientList(), hasRole(this.props.authReducer.user, ["SUPERADMIN", "ADMIN", "SYSTEM", "RECEPTIONIST"]) && this.getCalendarEvents(), hasRole(this.props.authReducer.user, ["SUPERADMIN", "ADMIN", "SYSTEM", "RECEPTIONIST"]) && this.getScheduleEvents()])
            .then(() => {
                hasRole(this.props.authReducer.user, ["DOCTOR"]) && this.setState({ selectedInfirmary: this.props.infirmaryReducer.infirmaryList.filter((d) => { return d.id === this.props.authReducer.user.currentInfirmary.id }) }, () => this.getCalendarEvents(this.props.authReducer.user.currentInfirmary.id))
            })
            .catch(error => {
            })
    }

    getScheduleEvents = (event) => {
        let { selectedDay } = this.state
        this.setState({ selectedBtn: event })
        switch (event) {
            case 'month':
                return hasRole(this.props.authReducer.user, ["SUPERADMIN", "ADMIN", "SYSTEM", "RECEPTIONIST"]) && this.props.getScheduleEvents({ startTime: moment(selectedDay).startOf('month').add(1, 'hours'), endTime: moment(selectedDay).endOf('month').add(1, 'hours') })
            case 'work_week':
                return hasRole(this.props.authReducer.user, ["SUPERADMIN", "ADMIN", "SYSTEM", "RECEPTIONIST"]) && this.props.getScheduleEvents({ startTime: moment(selectedDay).startOf('isoweek').add(1, 'hours'), endTime: moment(selectedDay).endOf('isoweek').add(1, 'hours') })
            case 'day':
                return hasRole(this.props.authReducer.user, ["SUPERADMIN", "ADMIN", "SYSTEM", "RECEPTIONIST"]) && this.props.getScheduleEvents({ startTime: moment(selectedDay).startOf('day').add(1, 'hours'), endTime: moment(selectedDay).endOf('day').add(1, 'hours') })
            default:
                return hasRole(this.props.authReducer.user, ["SUPERADMIN", "ADMIN", "SYSTEM", "RECEPTIONIST"]) && this.props.getScheduleEvents({ startTime: moment(selectedDay).startOf('day').add(1, 'hours'), endTime: moment(selectedDay).endOf('day').add(1, 'hours') })
        }
    }

    getScheduleEventsOnNavigateTabs = (event, view, action) => {
        this.setState({ selectedDay: event, selectedBtn: view })
        if (new Date().toDateString() === event.toDateString()) {
            this.setState({ navigateBtn: 'TODAY' })
        } else {
            this.setState({ navigateBtn: '' })
        }
        if (action === "DATE" && view === "month") {
            this.setState({ selectedDay: event, view: 'day', selectedBtn: 'day' })
            hasRole(this.props.authReducer.user, ["SUPERADMIN", "ADMIN", "SYSTEM", "RECEPTIONIST"]) && this.props.getScheduleEvents({ startTime: moment(event).startOf('day').add(1, 'hours'), endTime: moment(event).endOf('day').add(1, 'hours') })
        } else {
            switch (view) {
                case 'month':
                    return hasRole(this.props.authReducer.user, ["SUPERADMIN", "ADMIN", "SYSTEM", "RECEPTIONIST"]) && this.props.getScheduleEvents({ startTime: moment(event).startOf('month').add(1, 'hours'), endTime: moment(event).endOf('month').add(1, 'hours') })
                case 'work_week':
                    return hasRole(this.props.authReducer.user, ["SUPERADMIN", "ADMIN", "SYSTEM", "RECEPTIONIST"]) && this.props.getScheduleEvents({ startTime: moment(event).startOf('isoweek').add(1, 'hours'), endTime: moment(event).endOf('isoweek').add(1, 'hours') })
                case 'day':
                    return hasRole(this.props.authReducer.user, ["SUPERADMIN", "ADMIN", "SYSTEM", "RECEPTIONIST"]) && this.props.getScheduleEvents({ startTime: moment(event).startOf('day').add(1, 'hours'), endTime: moment(event).endOf('day').add(1, 'hours') })
                default:
                    return hasRole(this.props.authReducer.user, ["SUPERADMIN", "ADMIN", "SYSTEM", "RECEPTIONIST"]) && this.props.getScheduleEvents({ startTime: moment(event).startOf('day').add(1, 'hours'), endTime: moment(event).endOf('day').add(1, 'hours') })
            }
        }
    }

    getCalendarEventsOnNavigateTabs = (event, view, action) => {
        let { calendar } = this.state
        let params = {}
        this.setState({ selectedDay: event, selectedBtn: view })
        if (new Date().toDateString() === event.toDateString()) {
            this.setState({ navigateBtn: 'TODAY' })
        } else {
            this.setState({ navigateBtn: '' })
        }
        if (action === "DATE" && view === "month") {
            this.setState({ selectedDay: event, view: 'day', selectedBtn: 'day' })
            params.startTime = moment(event).startOf('day').add(1, 'hours')
            params.endTime = moment(event).endOf('day').add(1, 'hours')
            hasRole(this.props.authReducer.user, ["SUPERADMIN", "ADMIN", "SYSTEM", "RECEPTIONIST"]) && this.props.getCalendarEvents({ params: params })
        } else {
            params.infirmaryIds = hasRole(this.props.authReducer.user, ["DOCTOR"]) ? this.state.selectedInfirmary[0].id : calendar.infirmaryIds.length > 0 ? calendar.infirmaryIds.join(",") : null
            switch (view) {
                case 'month':
                    params.startTime = moment(event).startOf('month').add(1, 'hours')
                    params.endTime = moment(event).endOf('month').add(1, 'hours')
                    return hasRole(this.props.authReducer.user, ["SUPERADMIN", "ADMIN", "SYSTEM", "RECEPTIONIST"]) && this.props.getCalendarEvents({ params: params }).then(this.setState({ isLoadingEvents: false }))
                case 'work_week':
                    params.startTime = moment(event).startOf('isoweek').add(1, 'hours')
                    params.endTime = moment(event).endOf('isoweek').add(1, 'hours')
                    return hasRole(this.props.authReducer.user, ["SUPERADMIN", "ADMIN", "SYSTEM", "RECEPTIONIST"]) && this.props.getCalendarEvents({ params: params }).then(this.setState({ isLoadingEvents: false }))
                case 'day':
                    params.startTime = moment(event).startOf('day').add(1, 'hours')
                    params.endTime = moment(event).endOf('day').add(1, 'hours')
                    return hasRole(this.props.authReducer.user, ["SUPERADMIN", "ADMIN", "SYSTEM", "RECEPTIONIST", "DOCTOR"]) && this.props.getCalendarEvents({ params: params }).then(this.setState({ isLoadingEvents: false }))
                default:
                    params.startTime = moment(event).startOf('day').add(1, 'hours')
                    params.endTime = moment(event).endOf('day').add(1, 'hours')
                    return hasRole(this.props.authReducer.user, ["SUPERADMIN", "ADMIN", "SYSTEM", "RECEPTIONIST"]) && this.props.getCalendarEvents({ params: params }).then(this.setState({ isLoadingEvents: false }))
            }
        }
    }

    getCalendarEvents = (infirmaryIds) => {
        let { calendar, selectedDay, selectedBtn } = this.state;
        let params = {}
        params.infirmaryIds = calendar.infirmaryIds.length > 0 ? calendar.infirmaryIds.join(",") : infirmaryIds && infirmaryIds.length > 0 ? infirmaryIds.join(",") : infirmaryIds;
        switch (selectedBtn) {
            case 'month':
                params.startTime = moment(selectedDay).startOf('month').add(1, 'hours')
                params.endTime = moment(selectedDay).endOf('month').add(1, 'hours')
                return hasRole(this.props.authReducer.user, ["SUPERADMIN", "ADMIN", "SYSTEM", "RECEPTIONIST"]) && this.props.getCalendarEvents({ params: params }).then(this.setState({ isLoadingEvents: false }))
            case 'work_week':
                params.startTime = moment(selectedDay).startOf('isoweek').add(1, 'hours')
                params.endTime = moment(selectedDay).endOf('isoweek').add(1, 'hours')
                return hasRole(this.props.authReducer.user, ["SUPERADMIN", "ADMIN", "SYSTEM", "RECEPTIONIST"]) && this.props.getCalendarEvents({ params: params }).then(this.setState({ isLoadingEvents: false }))
            case 'day':
                params.startTime = moment(selectedDay).startOf('day').add(1, 'hours')
                params.endTime = moment(selectedDay).endOf('day').add(1, 'hours')
                return hasRole(this.props.authReducer.user, ["SUPERADMIN", "ADMIN", "SYSTEM", "RECEPTIONIST", "DOCTOR"]) && this.props.getCalendarEvents({ params: params }).then(this.setState({ isLoadingEvents: false }))
            default:
                params.startTime = moment(selectedDay).startOf('day').add(1, 'hours')
                params.endTime = moment(selectedDay).endOf('day').add(1, 'hours')
                return hasRole(this.props.authReducer.user, ["SUPERADMIN", "ADMIN", "SYSTEM", "RECEPTIONIST"]) && this.props.getCalendarEvents({ params: params }).then(this.setState({ isLoadingEvents: false }))
        }
    }

    closeDialog = () => {
        this.setState({ dialogOpen: false, calendarEventToEdit: null })
    }

    closeSearchPatientDialog = () => {
        this.setState({ searchPatientDialog: false })
    }

    handleChangeInfirmaries = (e, infirmary) => {
        let { calendar } = this.state
        let selectedInfirmary = { ...this.state.selectedInfirmary }
        selectedInfirmary = infirmary;
        calendar.infirmaryIds = []
        selectedInfirmary.forEach(data => {
            calendar.infirmaryIds.push(data.id)
        })
        this.setState({ selectedInfirmary: selectedInfirmary, calendar: calendar }, () => this.getCalendarEvents(calendar.infirmaryIds));
    }

    errorMessage = () => {
        if (hasRole(this.props.authReducer.user, ["DOCTOR"])) return
        if (this.state.selectedBtn === "month") return
        store.dispatch(snackActions.warning(this.props.t("first_select_infirmary")))
    }

    handleClickDoctorScheduleCard = (item) => {
        this.setState({ view: 'day', selectedBtn: 'day' }, () => this.getScheduleEventsOnNavigateTabs(new Date(item.startTime), "day"))

    }

    goToDay = (startTime) => {
        let calendar = this.state
        calendar.infirmaryIds = []
        this.setState({ selectedDay: new Date(startTime), selectedBtn: 'day', navigateBtn: '', searchPatientDialog: false, selectedInfirmary: [], calendar: calendar }, () => this.getCalendarEvents())
    }

    onView = (event) => {
        this.setState({ selectedBtn: event }, () => { this.getScheduleEvents(event); this.getCalendarEvents(); })
    }

    onNavigate = (event, view, action) => {
        this.getScheduleEventsOnNavigateTabs(event, view, action);
        this.getCalendarEventsOnNavigateTabs(event, view, action)
    }

    render() {
        const { selectedInfirmary, selectedSlot, isLoadingEvents, selectedBtn } = this.state
        const { isLoading, calendarEvents } = this.props.calendarReducer;
        const { infirmaryList } = this.props.infirmaryReducer
        const { scheduleEvents } = this.props.scheduleReducer

        if (isLoading) {
            return (
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            )
        }

        if (isLoadingEvents) {
            return (
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            )
        }

        const customDayPropGetter = (date) => {
            let dayOfWeek = date.getDay();
            if (dayOfWeek === 0 || dayOfWeek === 6)
                return {
                    className: "day_weekend",
                    style: {
                        border: 'solid 1px ' + (dayOfWeek === 0 || dayOfWeek === 6 ? '#fc4242' : 'none'),
                    },
                }
            else return {}
        }

        return (<>
            {hasRole(this.props.authReducer.user, ["SUPERADMIN", "ADMIN", "SYSTEM", "RECEPTIONIST"]) && <Grid container direction="row" spacing={2}>
                <Grid item container direction="column" xs={12} sm={8} md={8} lg={8} xl={8}>
                    <Grid container direction="row" spacing={2}>
                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={7} xl={7}>
                            <FormControl>
                                <WrappedAutocomplete
                                    label={this.props.t("infirmary")}
                                    placeholder={this.props.t("select_infirmary")}
                                    name="selectedInfirmary"
                                    value={selectedInfirmary}
                                    multiple={true}
                                    getOptionLabel={(option) => option ? option.name : ""}
                                    options={infirmaryList}
                                    onChange={(event, infirmary) => {
                                        this.handleChangeInfirmaries(event, infirmary)
                                    }}
                                    disabled={hasRole(this.props.authReducer.user, ["DOCTOR"])}
                                    renderOption={(props, option) => {
                                        return (
                                            <span {...props}>
                                                {option.name}
                                                <Chip style={{ marginLeft: 'auto', backgroundColor: option.calendarHexColor, width: '32px' }}></Chip>
                                            </span>
                                        );
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item container direction="column" xs={12} sm={10} md={8} lg={3} xl={3}>
                            <FormControl>
                                <Button color="primary" className="default-button" style={{ marginLeft: '5px', marginTop: '10px' }} onClick={() => this.setState({ searchPatientDialog: true })} >
                                    <Search />{this.props.t("patient_arrival_search")}
                                </Button>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>}
            <br />
            <Grid container direction="row">
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={hasRole(this.props.authReducer.user, ["SUPERADMIN", "ADMIN", "SYSTEM", "RECEPTIONIST"]) ? 10 : 12} xl={hasRole(this.props.authReducer.user, ["SUPERADMIN", "ADMIN", "SYSTEM", "RECEPTIONIST"]) ? 10 : 12}>
                    <Grid item container>
                        <Scheduler
                            localizer={momentLocalizer(moment)}
                            events={calendarEvents}
                            defaultDate={new Date()}
                            date={this.state.selectedDay}
                            dayPropGetter={customDayPropGetter}
                            startAccessor={(event) => { return new Date(moment(event.startTime).utc()) }}
                            endAccessor={(event) => { return new Date(moment(event.endTime).utc()) }}
                            titleAccessor={(event) => { return <> <span> {event.patient.firstName + " " + event.patient.lastName + " (" + event.patient.phone + ") " + event.comment}</span></> }}
                            min={new Date(0, 0, 0, 8, 0, 0)}
                            max={new Date(0, 0, 0, 21, 30, 0)}
                            components={{
                                toolbar: props => (
                                    <CustomToolbar {...props} selectedBtn={selectedBtn} navigateBtn={this.state.navigateBtn} view={this.state.view} selectedDay={this.state.selectedDay} />),
                                event: CustomEvent
                            }}
                            dayLayoutAlgorithm={'no-overlap'}
                            timeslots={1}
                            step={15}
                            onSelectSlot={(slot) => hasRole(this.props.authReducer.user, ["DOCTOR"]) || this.state.calendar.infirmaryIds.length === 0 || this.state.calendar.infirmaryIds.length > 1 ? this.errorMessage() : this.setState({ dialogOpen: true, selectedSlot: slot })}
                            onSelectEvent={hasRole(this.props.authReducer.user, ["DOCTOR"]) ? null : event => event.id && this.setState({ dialogOpen: true, calendarEventToEdit: event })}
                            selectable
                            defaultView='day'
                            onView={this.onView}
                            onNavigate={this.onNavigate}
                            views={['month', 'work_week', 'day']}
                            culture={localStorage.getItem('i18nextLng') === 'hr' ? 'hr' : 'en'}
                            messages={localStorage.getItem('i18nextLng') === 'hr' ? {
                                date: this.props.t("date"),
                                time: this.props.t("time"),
                                event: this.props.t("event"),
                                allDay: this.props.t("all_day"),
                                week: this.props.t("week"),
                                work_week: this.props.t("work_week"),
                                day: this.props.t("day"),
                                month: this.props.t("month"),
                                previous: this.props.t("back"),
                                next: this.props.t("cal_next"),
                                yesterday: this.props.t("yesterday"),
                                tomorrow: this.props.t("tomorrow"),
                                today: this.props.t("today"),
                                agenda: this.props.t("agenda"),
                                noEventsInRange: this.props.t("no_event")
                            } : {}}
                            style={{ height: selectedBtn === "month" && "85vh", width: "100%" }}
                            eventPropGetter={event => {
                                const backgroundColor = event.infirmary.calendarHexColor ? event.infirmary.calendarHexColor : "#99d996";
                                const color = event.infirmary.calendarHexColor ? "black" : "white";
                                return { style: { backgroundColor, color } };
                            }}
                        />
                    </Grid>
                </Grid>
                {hasRole(this.props.authReducer.user, ["SUPERADMIN", "ADMIN", "SYSTEM", "RECEPTIONIST"]) &&
                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={2} xl={2} >
                        <PerfectScrollbar style={{ maxHeight: selectedBtn === "month" ? "85vh" : '2000px' }}>
                            <Grid item container justifyContent="center" alignItems="center">
                                <Typography variant="h6" mt={5}>
                                    <span>{this.props.t("schedule")}</span>
                                    <Divider />
                                </Typography>
                            </Grid>
                            <Grid item style={{ width: '95%' }}>
                                {scheduleEvents.map((item, index) => {
                                    return <Card key={index} style={{ cursor: 'pointer', backgroundColor: item.infirmary.calendarHexColor, marginTop: '5px', marginLeft: '10px', height: '115px', width: '100%', fontSize: '13px', borderRadius: '10%' }} onClick={() => this.handleClickDoctorScheduleCard(item)}>
                                        <CardContent>
                                            <Grid container direction="row" spacing={2}>
                                                <Grid item container direction="column" xs={3} sm={3} md={3} lg={3} xl={3} style={{ textAlign: 'center' }}>
                                                    <PersonPinIcon style={{ fontSize: '50px', marginLeft: '10%', color: 'grey' }} />
                                                </Grid>
                                                <Grid item container direction="column" xs={9} sm={9} md={9} lg={9} xl={9} style={{ textAlign: 'left' }}>
                                                    <div>
                                                        <p><b>{moment(item.startTime).format("DD.MM.yyyy.")}</b></p>
                                                        <p><b>{moment(item.startTime).format("HH:mm") + " - " + moment(item.endTime).format("HH:mm")}</b></p>
                                                        <p><b>
                                                            {item.user.firstName && item.user.firstName.length > 8 ? <Tooltip
                                                                disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={item.user.firstName} style={{ cursor: 'pointer' }}>
                                                                <span>{item.user.firstName && item.user.firstName.length > 8 ? item.user.firstName.substring(0, 8) + "..." : item.user.firstName}</span>
                                                            </Tooltip> :
                                                                item.user.firstName}{" "}
                                                            {item.user.lastName && item.user.lastName.length > 8 ? <Tooltip
                                                                disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={item.user.lastName} style={{ cursor: 'pointer' }}>
                                                                <span>{item.user.lastName && item.user.lastName.length > 8 ? item.user.lastName.substring(0, 8) + "..." : item.user.lastName}</span>
                                                            </Tooltip> :
                                                                item.user.lastName}</b></p>
                                                        <p><b>
                                                            {item.infirmary.name && item.infirmary.name.length > 15 ? <Tooltip
                                                                disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={item.infirmary.name} style={{ cursor: 'pointer' }}>
                                                                <span>{item.infirmary.name && item.infirmary.name.length > 15 ? item.infirmary.name.substring(0, 15) + "..." : item.infirmary.name}</span>
                                                            </Tooltip> :
                                                                item.infirmary.name}
                                                        </b></p>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                })}
                            </Grid>
                        </PerfectScrollbar>
                    </Grid>
                }
                {
                    hasRole(this.props.authReducer.user, ["SUPERADMIN", "ADMIN", "SYSTEM", "RECEPTIONIST"]) && /* this.state.dialogOpen &&  */ <CreateOrUpdateEvent
                        isOpen={this.state.dialogOpen}
                        onClose={this.closeDialog}
                        selectedSlot={selectedSlot}
                        calendarEventToEdit={this.state.calendarEventToEdit}
                        calendar={this.state.calendar}
                        infirmary={selectedInfirmary}
                        onSave={() => this.setState({
                            dialogOpen: false,
                            calendarEventToEdit: null
                        }, () => this.getCalendarEvents())}
                    />
                }
                <SearchPatientCalendarEntryModal
                    isOpen={this.state.searchPatientDialog}
                    onClose={this.closeSearchPatientDialog}
                    goToDay={(startTime) => this.goToDay(startTime)} />
            </Grid>
        </>
        );
    }
}

const mapStateToProps = (state) => ({
    calendarReducer: state.calendarReducer,
    infirmaryReducer: state.infirmaryReducer,
    scheduleReducer: state.scheduleReducer,
    authReducer: state.authReducer
})

const mapActionsToProps = { getInfirmaryList, getPatientList, getCalendarEvents, deleteCalendarEntry, getScheduleEvents }

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(Calendar))
