import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
    Grid,
    Button,
    Typography,
    Card,
    CardHeader,
    FormControl,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Tooltip,
    Zoom
} from '@mui/material';
import './Statistics.css'
import { getPriceEstimateStatistics } from '../../redux/calendar/actions'
import { QueryStats } from '@mui/icons-material';
import WrappedDatePicker from '../../components/common/WrappedDatePicker';
import moment from 'moment';
import NumberFormat from 'react-number-format';

class DashboardStatisticsProfit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedStartDate: null,
            selectedEndDate: null
        };
    }

    componentWillUnmount() {
        this.props.calendarReducer.priceEstimateStatistics = null
    }

    handleChangeStartDate = (date) => {
        this.setState({ selectedStartDate: date });
    }

    handleChangeEndDate = (date) => {
        this.setState({ selectedEndDate: date });
    }

    getStatistics = () => {
        const { selectedStartDate, selectedEndDate } = this.state;
        let params = {}
        params.startTime = selectedStartDate !== null ? moment(selectedStartDate) : null
        params.endTime = selectedEndDate !== null ? moment(selectedEndDate) : null
        console.log(params)
        this.props.getPriceEstimateStatistics({ params: params })
    }

    render() {
        const { selectedStartDate, selectedEndDate } = this.state
        const { priceEstimateStatistics } = this.props.calendarReducer

        let title = priceEstimateStatistics && <div>
            <p><Typography>{this.props.t("profit")}</Typography></p>
            <p><strong>{`${moment.unix(priceEstimateStatistics.startDate).format("DD.MM.YYYY.")} - ${moment.unix(priceEstimateStatistics.endDate).format("DD.MM.YYYY.")}`}</strong></p>
            <p><NumberFormat style={{ fontWeight: 'bold', fontSize: '20px' }} value={priceEstimateStatistics.priceEstimateSum === null ? 0 : priceEstimateStatistics.priceEstimateSum} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} suffix=" EUR" /></p>
        </div>

        let priceNotSpecifiedList = priceEstimateStatistics && priceEstimateStatistics.priceNotSpecifiedList.map((data, index) => {
            return <ListItem
                key={index}
                sx={{ border: '1px solid lightgrey' }}
            >
                <ListItemText primary={`${moment.unix(data.date).format("DD.MM.YYYY")}`} />
                <ListItemAvatar>
                    <Tooltip
                        disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={<span>
                            {this.props.t("number_of_not_specified_price")}
                        </span>}>
                        <Avatar sx={{ width: '30px', height: '30px', backgroundColor: '#6F8684', cursor: 'pointer' }}>
                            {data.priceNotSpecifiedCount}
                        </Avatar>
                    </Tooltip>
                </ListItemAvatar>
            </ListItem>
        })

        return (
            <Grid container direction="row" spacing={2}>
                <Grid item container direction="column" xs={5} sm={5} md={3} lg={3} xl={2}>
                    <Grid item container>
                        <FormControl>
                            <WrappedDatePicker
                                label="start_date"
                                name="startDate"
                                maxDate={moment(new Date()).add(1, 'year')}
                                onChange={this.handleChangeStartDate}
                                value={selectedStartDate ? Date.parse(selectedStartDate) : null}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item container direction="column" xs={5} sm={5} md={3} lg={3} xl={2}>
                    <Grid item container>
                        <FormControl>
                            <WrappedDatePicker
                                label="end_date"
                                name="endDate"
                                maxDate={moment(new Date()).add(1, 'year')}
                                onChange={this.handleChangeEndDate}
                                value={selectedEndDate ? Date.parse(selectedEndDate) : null}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item container direction="column" xs={2} sm={2} md={1} lg={1} xl={1}>
                    <Grid item container>
                        <FormControl>
                            <Button
                                style={{ marginTop: '8px', height: '40px', width: '70px' }}
                                title={this.props.t("get_statistics")}
                                color="primary"
                                onClick={() => this.getStatistics()}
                                disabled={!selectedEndDate}>
                                <QueryStats />
                            </Button>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container direction="row" spacing={2}>
                        <Grid item container direction="column" xs={6} sm={6} md={3} lg={3} xl={3}>
                            {priceEstimateStatistics && <Card>
                                <CardHeader subheader={title} />
                            </Card>}
                        </Grid>
                        <Grid item container direction="column" xs={6} sm={6} md={3} lg={3} xl={3}>
                            {priceEstimateStatistics && <>
                                {priceEstimateStatistics.priceNotSpecifiedList.length > 0 && <Typography variant='body1'>{this.props.t("price_not_specified")}</Typography>}
                                <Card>
                                    {priceNotSpecifiedList}
                                </Card>
                            </>}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    calendarReducer: state.calendarReducer
})

export default connect(mapStateToProps, { getPriceEstimateStatistics })(withTranslation()(DashboardStatisticsProfit))