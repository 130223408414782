import types from './types';

const initialState = {
    loadingUsers: false,
    users: [],
    usersTotalElements: 0,
    usersParams: {
        page: 0,
        size: 10
    },
    roles: [],
    doctors: []
}

export default function user(state = initialState, action) {
    switch (action.type) {
        case types.SET_USERS_LOADING:
            return {
                ...state,
                loadingUsers: true
            }
        case types.SET_USERS:
            return {
                ...state,
                users: action.payload.content,
                usersTotalElements: action.payload ? action.payload.totalElements : 0,
                loadingUsers: false
            }
        case types.SET_USERS_PAGE:
            return {
                ...state, usersParams: { ...state.usersParams, page: action.payload }
            }
        case types.SET_USERS_SIZE:
            return {
                ...state, usersParams: { ...state.usersParams, size: action.payload, page: 0 }
            }
        case types.SET_USERS_PARAMS:
            return {
                ...state,
                usersParams: action.payload
            }
        case types.SET_ROLES:
            return {
                ...state,
                roles: action.payload
            }
        case types.SET_DOCTORS:
            return {
                ...state,
                doctors: action.payload
            }
        default:
            return state;
    }
}