import axios from '../axiosConfig';

export async function getLoggedUser(config = {}) {
    return await axios.get('/v1/user/info', config);
}

export async function logout(config = {}) {
    return await axios.get(`/logout`, config);
}

export async function unsubscribe(googleFormId, config = {}) {
    return await axios.get(`/v1/notifications/public/email-unsubscribe/${googleFormId}`, config);
}

const actions = {
    getLoggedUser,
    logout,
    unsubscribe
}

export default actions;